/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Avatar, Image, Layout, Menu, Typography } from "antd";
import {
  ApiOutlined,
  CreditCardOutlined,
  ProfileOutlined,
  UserOutlined,
  SettingOutlined,
  MenuOutlined,
  AreaChartOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import "./index.css";
import ProfileSettings from "../login/profileSettings/ProfileSettings";
import logoSmallWhite from "../../assets/onePOS/logo-small-white.png";
import logoBigWhite from "../../assets/onePOS/logo-big-white.png";
import { getDeployEnvironment } from "../../utils/getDeployEnvironment";
import { GlobalContext } from "../context/GlobalContext";
import { Watermark } from "../../../node_modules/antd/es/index";
const { Header, Content, Footer, Sider } = Layout;

const MainLayout = ({ children }) => {
  const { loggedUser } = useContext(GlobalContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [collapsed, setCollapsed] = useState(true);
  const [collapsedWidth, setCollapsedWidth] = useState(80); // Valor por defecto del menú plegado
  const [sideBarMenuKey, setSideBarMenuKey] = useState();
  const [pathnameHeader, setPathnameHeader] = useState("");

  const items = [
    {
      key: "1",
      icon: <AreaChartOutlined />,
      label: "Dashboard",
      path: "/dashboard",
    },
    {
      key: "2",
      icon: <ApiOutlined />,
      label: "Integraciones",
      path: "/integraciones",
    },
    {
      key: "3",
      icon: <CreditCardOutlined />,
      label: "Medios de pago",
      path: "/mediospago",
    },
    {
      key: "4",
      icon: <ProfileOutlined />,
      label: "Planes de pago",
      path: "/planespago",
    },
    {
      key: "5",
      icon: <SettingOutlined />,
      label: "Configuración",
      path: "/configuracion",
    },
  ];

  useEffect(() => {
    // seguimiento de ruta para menu item active
    const pathname = location.pathname;

    determineCurrentPathForHeader(pathname);
    const foundItem = items.find((item) => item.path === pathname);
    if (foundItem) {
      setSideBarMenuKey(foundItem.key);
    }
  }, [location.pathname, items]);

  useEffect(() => {
    // comienza estando plegado el menú
    setCollapsed(true);

    const handleResize = () => {
      // Obtener el ancho de la pantalla
      const screenWidth = window.innerWidth;

      // Establecer collapsedWidth basado en el tamaño de la pantalla
      if (screenWidth <= 768) {
        // Puedes ajustar este valor según tus necesidades de diseño responsive
        setCollapsedWidth(0); // Tamaño 0 para mobile
      } else {
        setCollapsedWidth(80); // Tamaño 80 para otros tamaños de pantalla
      }
    };

    // Ejecutar handleResize al cargar y al redimensionar la ventana
    handleResize();
    window.addEventListener("resize", handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const determineCurrentPathForHeader = (pathname) => {
    switch (true) {
      case pathname === "/integraciones":
        setPathnameHeader("Integraciones");
        break;
      case pathname === "/mediospago":
        setPathnameHeader("Medios de pago");
        break;
      case pathname === "/planespago":
        setPathnameHeader("Planes de pago");
        break;
      case pathname === "/configuracion":
        setPathnameHeader("Configuración");
        break;
      case pathname === "/dashboard":
        setPathnameHeader("Dashboard");
        break;
      default:
        break;
    }
  };

  const renderTriggerIcon = () => {
    if (collapsedWidth === 0) {
      return (
        <MenuOutlined
          onClick={() => setCollapsed(!collapsed)} // Toggle collapse al hacer clic
          style={{
            fontSize: "18px",
            color: "black",
            cursor: "pointer",
          }}
        />
      );
    }
    return false;
  };

  return (
    <Layout style={{ height: "100dvh" }}>
      <Sider
        breakpoint="lg"
        collapsedWidth={collapsedWidth}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div
          className="sider-image-wrapper"
          onClick={() => {
            setCollapsed(!collapsed); // Toggle el estado de collapse
            navigate("/integraciones");
          }}
        >
          <Image
            width={collapsed ? 35 : 120}
            src={collapsed ? logoSmallWhite : logoBigWhite}
            preview={false}
            className={collapsed ? "" : ""}
          />
        </div>
        <Menu
          items={items}
          theme="dark"
          selectedKeys={[sideBarMenuKey]}
          mode="inline"
          onSelect={({ key }) => {
            const selectedItem = items.find((item) => item.key === key);
            if (selectedItem) {
              setSideBarMenuKey(selectedItem.key);
              navigate(selectedItem.path);
            }
          }}
        ></Menu>
      </Sider>
      <Layout>
        <Header className="header-wrapper">
          {renderTriggerIcon()}
          <Typography.Text style={{ fontWeight: 500 }}>
            {pathnameHeader}
          </Typography.Text>
          <ProfileSettings>
            <div className="profile-info">
              {collapsed || collapsedWidth !== 0 ? (
                <Typography.Text
                  className="typo-header"
                  style={{
                    fontWeight: 500,
                    marginRight: 12,
                    cursor: "pointer",
                  }}
                >
                  {loggedUser?.nombreEmpresa}
                </Typography.Text>
              ) : null}
              <Avatar
                className="avatar-header"
                src={`${process.env.REACT_APP_IMAGES_URL}${loggedUser?.imagen}`}
                size={32}
                icon={<UserOutlined />}
                style={{ cursor: "pointer" }}
              />
            </div>
          </ProfileSettings>
        </Header>
        <Content
          style={{
            margin: "12px 12px",
            display: "flex",
            flexDirection: "column",
          }}
          // style={{ margin: "12px 12px" }}
        >
          {/* De esta manera evito que el iframe quedé solapado en el body */}
          <div
            style={{ flex: 1 }}
            className={
              pathnameHeader !== "Dashboard"
                ? "body-wrapper"
                : "body-wrapper-nopad"
            }
          >
            {children}
          </div>
        </Content>
        <Watermark content={getDeployEnvironment()} gap={[20, 20]}>
          <Footer
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "10px",
            }}
          >
            <Typography.Text type="secondary" style={{ fontSize: "12px" }}>
              © {new Date().getFullYear()} Innercor Soluciones ERP
            </Typography.Text>
          </Footer>
        </Watermark>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
