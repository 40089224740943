import React, { useState, useEffect } from "react";
import { Layout, Typography } from "antd";
import { Image } from "../../../node_modules/antd/es/index";
import logoOnePos from "../../assets/onePOS/logo_color.svg";
import "./index.css";
import HEADValidarEstado from "../../helpers/apiHealth/HEADValidarEstado";

const APIHealth = (WrappedComponent) => {
  return function ApiCheckComponent(props) {
    const [apiAvailable, setApiAvailable] = useState(true);

    useEffect(() => {
      const fetchAPIStatus = async () => {
        const apiStatus = await HEADValidarEstado();

        if (apiStatus && apiStatus.status !== 200) {
          setApiAvailable(false); // Marca la API como no disponible
          return;
        }
      };
      fetchAPIStatus();
    }, []);

    if (!apiAvailable) {
      return (
        <div className="health-app-container">
          <div className="health-main-content">
            <div className="health-api-check-wrapper">
              <div className="health-image-wrapper">
                <Image
                  src={logoOnePos}
                  preview={false}
                  style={{ width: 200 }}
                />
              </div>
              <Typography.Text strong className="health-main-text">
                500
              </Typography.Text>
              <div className="health-bottom-wrapper">
                <Typography.Title level={5}>
                  El servicio no está disponible, intentelo nuevamente más
                  tarde.
                </Typography.Title>
              </div>
            </div>
          </div>
          <Layout.Footer style={{ textAlign: "center", height: 50 }}>
            <Typography.Text type="secondary" style={{ fontSize: "12px" }}>
              © {new Date().getFullYear()} Innercor Soluciones ERP
            </Typography.Text>
          </Layout.Footer>
        </div>
      );
    }

    return <WrappedComponent {...props} />;
  };
};

export default APIHealth;
