import React from "react";
import { Image } from "../../../node_modules/antd/es/index";
import logoOnePOS from "../../assets/onePOS/logo-onepos.png";

const SpinOnePOS = () => {
  return (
    <div style={{ animation: "breathing 1.5s ease-in-out infinite" }}>
      <Image src={logoOnePOS} preview={false} width={45} />
    </div>
  );
};

export default SpinOnePOS;
