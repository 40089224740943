import jwtEncode from "jwt-encode";

// Función para crear el Token JWT
export const generateJwtToken = (payload, secretKey) => {
  return jwtEncode(payload, secretKey);
};

// Función para generar la URL del iframe de MetaBase
export const generateIframeURL = (token, siteUrl) => {
  return `${siteUrl}/embed/dashboard/${token}#bordered=true&titled=true`;
};
