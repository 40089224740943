import { useContext, useState, useEffect } from "react";
import MainLayout from "../mainLayout/MainLayout";
import SpinOnePOS from "../spinOnePOS/SpinOnePOS";
import { GlobalContext } from "../context/GlobalContext";
import {
  generateIframeURL,
  generateJwtToken,
} from "../../utils/generateIframeURL";
import GETDashboard from "../../helpers/dashboard/GETDashboard";

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [metabaseURL, setMetabaseURL] = useState("");
  const { loggedUser } = useContext(GlobalContext);

  useEffect(() => {
    const fetchData = async () => {
      const response = await GETDashboard(loggedUser);
      if (response.status === 200) {
        const { sitioURl, claveSecreta, nroPanel, tpoExp } = response.data;
        const payload = {
          resource: { dashboard: nroPanel },
          params: nroPanel === 2 ? { empresa: [loggedUser.codEmpresa] } : {},
          exp: Math.round(Date.now() / 1000) + tpoExp * 60,
        };
        const token = generateJwtToken(payload, claveSecreta);
        const iframeUrl = generateIframeURL(token, sitioURl);

        setMetabaseURL(iframeUrl);
      }
    };
    fetchData();
  }, [loggedUser]);

  const handleIframeLoad = () => {
    // Cambiar estado de loading a false cuando el iframe haya cargado
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  return (
    <MainLayout>
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        {loading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              zIndex: 10,
            }}
          >
            <SpinOnePOS />
          </div>
        )}

        {metabaseURL ? (
          <iframe
            title="metabase-dashboard"
            frameBorder={0}
            nonce="imxL5ehVqX"
            src={metabaseURL} // Aquí usamos la URL generada
            width="100%"
            height="100%"
            sandbox="allow-scripts allow-same-origin allow-downloads"
            loading="lazy"
            onLoad={handleIframeLoad} // Detecta cuando el iframe termina de cargar
          />
        ) : (
          <h3>NA</h3> // Si no hay URL, muestra NA
        )}
      </div>
    </MainLayout>
  );
};

export default Dashboard;
