import axios from "axios";

const GETDashboard = async (loggedUser) => {
  try {
    const { codEmpresa, tokenDatos } = loggedUser;
    
    const config = {
      method: "GET",
      maxBodyLength: Infinity, // Opcional, para requests grandes
      url: `${process.env.REACT_APP_API_URL}/api/Dashboard/ObtenerDashboard/${codEmpresa}`,
      headers: {
        Accept: "text/plain",
        Authorization: `Bearer ${tokenDatos.accessToken}`,
      },
    };

    const response = await axios.request(config);

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    if (error.response) {
      // Error de respuesta del servidor
      return { status: error.response.status, data: error.response.data };
    } else if (error.request) {
      // No se recibió respuesta del servidor
      return { status: 500, message: "No se recibió respuesta del servidor" };
    } else {
      // Otro tipo de error
      return { status: 400, message: "Error al realizar la solicitud" };
    }
  }
};

export default GETDashboard;
