export const getDeployEnvironment = () => {
  const hostname = window.location.hostname;

  if (hostname === "oneposqa.innercor.com.ar") {
    return "QA";
  } else if (hostname === "localhost") {
    const apiUrl = process.env.REACT_APP_API_URL || "";
    if (apiUrl.includes("oneposapiqa")) {
      return "Local - QA";
    } else if (apiUrl.includes("oneposapi")) {
      return "Local - PROD";
    }
    return "Local";
  } else if (hostname === "oneposdev.innercor.com.ar") {
    return "Dev";
  }
};
