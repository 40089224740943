import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../login/Login";
import MediosPago from "../mediosPago/MediosPago";
import PlanesPago from "../planesPago/PlanesPago";
import NotFound from "../notFound/NotFound";
import PrivateRoute from "./PrivateRoute";
import Integraciones from "../integraciones/Integraciones";
import LinkPago from "../linkPago/LinkPago";
import Configuracion from "../configuracion/Configuracion";
import Dashboard from "../dashboard/Dashboard";

const AppRoutes = ({ loggedUser, allIntegrations }) => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          Object.keys(loggedUser).length > 0 ? (
            <Navigate to="/dashboard" replace />
            // <Navigate to="/integraciones" replace />
          ) : (
            <Login />
          )
        }
      />

      <Route
        path="/dashboard"
        element={
          <PrivateRoute element={<Dashboard />} loggedUser={loggedUser} />
        }
      />
      <Route
        path="/integraciones"
        element={
          <PrivateRoute
            element={<Integraciones integrations={allIntegrations} />}
            loggedUser={loggedUser}
          />
        }
      />
      <Route
        path="/mediospago"
        element={
          <PrivateRoute
            element={<MediosPago integrations={allIntegrations} />}
            loggedUser={loggedUser}
          />
        }
      />
      <Route
        path="/planespago"
        element={
          <PrivateRoute
            element={<PlanesPago integrations={allIntegrations} />}
            loggedUser={loggedUser}
          />
        }
      />
      <Route
        path="/configuracion"
        element={
          <PrivateRoute element={<Configuracion />} loggedUser={loggedUser} />
        }
      />
      <Route path="/linkPago/:codLinkPago" element={<LinkPago />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
