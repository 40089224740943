import axios from "axios";

export const DELETESucursal = async (dataSucursal, loggedUser) => {
  try {
    const { tokenDatos } = loggedUser;

    let config = {
      method: "DELETE",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/api/Sucursales/${dataSucursal.codigoEmpresa}/${dataSucursal.codigo}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenDatos.accessToken}`,
      },
    };

    const response = await axios.request(config);

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    if (error.response) {
      return { status: error.response.status, data: error.response.data };
    } else if (error.request) {
      return { status: 500, message: "No se recibió respuesta del servidor" };
    } else {
      return { status: 400, message: "Error al realizar la solicitud" };
    }
  }
};
