/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {
  lazy,
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  Card,
  Image,
  Input,
  message,
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import {
  SearchOutlined,
  EditOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { GlobalContext } from "../context/GlobalContext";
import MainLayout from "../mainLayout/MainLayout";
import { v4 as uuidv4 } from "uuid"; // Genero uuid diferentes para evitar repetición en prop key
import "./index.css";
import GETListarMediosPago from "../../helpers/integraciones/GETListarMediosPago";
import GETObtenerIntegracion from "../../helpers/integraciones/GETObtenerIntegracion";
import dayjs from "dayjs";
import { exportMPToXLS } from "../../utils/exportMPToXLS";
import SpinOnePOS from "../spinOnePOS/SpinOnePOS";
import { Switch } from "../../../node_modules/antd/es/index";

const DrawerMediosPago = lazy(() =>
  import("./drawerMediosPago/DrawerMediosPago")
);

// Recibe como props integraciones al igual que Dashboard
const MediosPago = ({ integrations }) => {
  const searchInput = useRef(null);
  const [allData, setAllData] = useState([]); // Datos originales
  const [data, setData] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [selectedMedioPago, setSelectedMedioPago] = useState(null);
  const [lastSyncDate, setLastSyncDate] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [tooltipFilter, setTooltipFilter] = useState("Solo configurados"); // Al no tener un state con el valor real del switch, gestiono por string

  const { setSelectedIntegration, selectedIntegration, loggedUser } =
    useContext(GlobalContext);

  useEffect(() => {
    if (integrations && integrations.length > 0) {
      const fetchData = async () => {
        setLoadingTable(true);

        try {
          // Realiza ambas solicitudes en paralelo
          const [resultMediosPago, syncResult] = await Promise.all([
            GETListarMediosPago(loggedUser, selectedIntegration),
            selectedIntegration?.codigo === "MB"
              ? GETObtenerIntegracion(loggedUser, selectedIntegration)
              : Promise.resolve(null), // Si no es MB, devuelve una promesa resuelta con null
          ]);
          if (Array.isArray(resultMediosPago)) {
            setAllData(resultMediosPago); // Guardar todos los datos originales
            // Aplica el filtro inicial solo codERP

            const filtered = resultMediosPago?.filter((item) =>
              item.codigo.some((codigoItem) => codigoItem.codigoERP !== null)
            );

            setData(filtered); // Guarda los datos filtrados
          }

          if (syncResult) {
            setLastSyncDate(
              dayjs(syncResult.mobbexUltimaSincro).format("DD/MM/YYYY HH:mm")
            );
          }
        } catch (error) {
          // console.error("Error fetching data:", error);
        } finally {
          setLoadingTable(false);
        }
      };

      fetchData();
    }
  }, [selectedIntegration, integrations, loggedUser]);

  const handleDrawerClose = (updatedRecord) => {
    setOpenDrawer(false);
    if (updatedRecord) {
      setData((prevData) =>
        prevData.map((item) =>
          item.nombreMediosPago === updatedRecord.nombreMediosPago
            ? updatedRecord
            : item
        )
      );
    }
  };

  const renderFilter = () => {
    // Fx encargada de crear el header donde elegir integraciones
    if (integrations && integrations.length > 0) {
      return integrations.map((item, index) => {
        if (item.activo) {
          const imageSrc = `${process.env.REACT_APP_IMAGES_URL}${item.imagen}`;

          return (
            <Tooltip title={item.nombre} key={uuidv4()}>
              <Card
                style={{
                  width: "55px",
                  height: "55px",
                  borderColor: "#808080a1",
                  margin: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="integration-wrapper"
                onClick={() => toggleSelection(item)}
                bordered={
                  item.idIntegracion === selectedIntegration?.idIntegracion &&
                  true
                }
                hoverable
              >
                <Image src={imageSrc} preview={false} width={30} />
              </Card>
            </Tooltip>
          );
        }
        return null;
      });
    }
  };

  const getColumnSearchProps = (dataIndex, placeholder) => {
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
    };

    const handleReset = (clearFilters, confirm, dataIndex) => {
      clearFilters();
      handleSearch([""], confirm, dataIndex);
    };

    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Buscar por ${placeholder}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() =>
                clearFilters && handleReset(clearFilters, confirm, dataIndex)
              }
              size="small"
              style={{ width: 90 }}
            >
              Reiniciar
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        if (dataIndex === "codigo") {
          // Verificar si algún objeto dentro del array 'codigo' coincide con el valor de búsqueda
          const matchFound = record.codigo.some((item) => {
            return (
              item.codigoERP &&
              item.codigoERP.toLowerCase().includes(value.toLowerCase())
            );
          });

          return matchFound;
        } else {
          // Manejar la búsqueda para otros campos simples
          return record[dataIndex]
            ? record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
            : "";
        }
      },
      // onFilterDropdownOpenChange: (visible) => {
      //   if (visible) {
      //     setTimeout(() => searchInput.current?.select(), 100);
      //   }
      // },
      render: (text) => <span>{text}</span>,
    };
  };

  const handleModifyMedioPago = (record) => {
    setSelectedMedioPago(record);
    setOpenDrawer(true);
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombreMediosPago",
      ellipsis: true,
      width: "10%",
      editable: false,
      ...getColumnSearchProps("nombreMediosPago", "nombre"),
      render: (dataIndex, row) => {
        const imageSrc = `${process.env.REACT_APP_IMAGES_URL}${
          row.imagen || "/tarjetas/defaultcard.svg"
        }`;

        return (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography.Text strong>{dataIndex}</Typography.Text>
            {imageSrc && <Image src={imageSrc} width={35} preview={false} />}
          </div>
        );
      },
    },
    {
      title: "Tipo",
      dataIndex: "tipoMediosPago",
      width: "5%",
      editable: false,
      filters: [
        {
          text: "DÉBITO",
          value: "1",
        },
        {
          text: "CRÉDITO",
          value: "2",
        },
      ],
      onFilter: (value, record) => {
        return record?.tipoMediosPago === value;
      },
      render: (dataIndex) => {
        return <span>{dataIndex === "1" ? "DÉBITO" : "CRÉDITO"}</span>;
      },
    },
    {
      title: "Referencia ERP",
      dataIndex: "codigo",
      width: "20%",
      editable: true,
      ...getColumnSearchProps("codigo", "código"),
      render: (dataIndex, row) => {
        return (
          <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
            {dataIndex.map((item, idx) => {
              if (item.idMediosPagoERPIntegracion !== 0 && item.codigoERP) {
                return (
                  <Tag
                    className="custom-tag-mp"
                    bordered={false}
                    key={uuidv4()}
                    style={{
                      marginRight: "5px",
                      marginBottom: "5px",
                      backgroundColor: "#f0f0f0",
                    }}
                  >
                    {item.codigoERP}
                    {item.descripcionERP && ` - ${item.descripcionERP}`}
                  </Tag>
                );
              } else {
                return null;
              }
            })}
          </div>
        );
      },
    },
    {
      title: " ",
      dataIndex: "",
      width: "2%",
      key: "action",
      render: (_, record) => {
        return (
          <Tooltip title="Editar medio de pago">
            <EditOutlined
              className="edit-icon"
              onClick={() => {
                handleModifyMedioPago(record);
              }}
            />
          </Tooltip>
        );
      },
    },
  ];

  const toggleSelection = (integration) => {
    // Cambio de integración activa
    setSelectedIntegration(integration);
  };

  const locale = {
    emptyText: "No hay medios de pago para esta integración.",
  };

  const handleDownloadXLS = () => {
    try {
      exportMPToXLS(
        data,
        `medios_pago_${selectedIntegration.codigo}`,
        "MediosPago"
      );
    } catch (error) {
      message.error(error);
    }
  };

  const handleSwitchChange = (checked) => {
    setLoadingTable(true); // Muestra el indicador de carga
    setTooltipFilter(checked ? "Solo configurados" : "Todos");

    // Aplica el filtro según el valor de `checked`
    const filtered = checked
      ? data.filter((item) =>
          item.codigo.some((codigoItem) => codigoItem.codigoERP !== null)
        )
      : allData;

    setData(filtered);

    // Simula un retraso para la carga (opcional)
    setTimeout(() => {
      setLoadingTable(false); // Oculta el indicador de carga
    }, 600);
  };

  return (
    <MainLayout>
      <div className="filter-wrapper">
        <div className="filter-wrapper">{renderFilter()} </div>
        <div className="filter-right-wrapper">
          {selectedIntegration && allData?.length > 0 && (
            <Tooltip title={tooltipFilter}>
              <Switch
                size="small"
                defaultChecked
                onChange={handleSwitchChange}
              />
            </Tooltip>
          )}
          {/* {allData?.length > 0 && (
            <Select
              style={{ width: "200px" }}
              mode="tags"
              size="default"
              placeholder="Seleccionar ámbito"
              // value={selectedTags} // Establecer el valor actual de las etiquetas seleccionadas
              // onChange={handleChange} // Manejador de cambios
            >
              <Select.Option value="Regional">Regional</Select.Option>
              <Select.Option value="Nacional">Nacional</Select.Option>
              <Select.Option value="Internacional">Internacional</Select.Option>
            </Select>
          )} */}

          {selectedIntegration?.codigo === "MB" && lastSyncDate && (
            <Typography.Text type="secondary" style={{ fontSize: "12px" }}>
              Últ. sinc.: {lastSyncDate}
            </Typography.Text>
          )}
          {selectedIntegration && allData?.length > 0 && (
            <Popconfirm
              placement="right"
              title="¿Desea exportar la información a Excel?"
              onConfirm={handleDownloadXLS}
              okText="Sí"
              cancelText="No"
            >
              <Button
                style={{ marginLeft: 8, marginRight: 8 }}
                type="dashed"
                icon={
                  <FileExcelOutlined style={{ color: "green", fontSize: 14 }} />
                }
              />
            </Popconfirm>
          )}
        </div>
      </div>
      <div className="table-wrapper">
        <Table
          sticky={{ offsetHeader: 100 }}
          scroll={{ x: "max-content" }}
          loading={{
            spinning: loadingTable,
            indicator: (
              <div style={{ marginTop: 50 }}>
                <SpinOnePOS />
              </div>
            ),
          }}
          locale={locale}
          size="small"
          rowKey={(record) => `${record.idMediosPago + uuidv4()}`}
          bordered
          dataSource={Array.isArray(data) ? data : []}
          columns={columns}
          pagination={{
            showSizeChanger: true,
            defaultPageSize: 50,
          }}
        />
      </div>
      {openDrawer && (
        <Suspense fallback={<SpinOnePOS />}>
          <DrawerMediosPago
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            selectedMedioPago={selectedMedioPago}
            setSelectedMedioPago={setSelectedMedioPago}
            onClose={handleDrawerClose}
          />
        </Suspense>
      )}
    </MainLayout>
  );
};

export default MediosPago;
