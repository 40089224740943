import axios from "axios";

/**
 * Cambiar contraseña del usuario
 * @param {Object} loggedUser - Información del usuario autenticado
 * @param {Object} passwordPayload - Datos necesarios para cambiar la contraseña
 * @returns {Object} - Respuesta del servidor
 */
const POSTCambiarClave = async (loggedUser, passwordPayload) => {
  try {
    const { tokenDatos } = loggedUser;

    let config = {
      method: "POST",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/api/Usuarios/CambiarClave`,
      headers: {
        Authorization: `Bearer ${tokenDatos.accessToken}`,
        "Content-Type": "application/json",
      },
      data: passwordPayload,
    };

    const response = await axios.request(config);

    if (response.status === 200) {
      return response; // Devuelve la respuesta en caso de éxito
    }
  } catch (error) {
    if (error.response) {
      return { status: error.response.status, data: error.response.data }; // Error del servidor
    } else if (error.request) {
      return { status: 500, message: "No se recibió respuesta del servidor" }; // Error en la comunicación
    } else {
      return { status: 400, message: "Error al realizar la solicitud" }; // Error desconocido
    }
  }
};

export default POSTCambiarClave;
