import axios from "axios";

const HEADValidarEstado = async () => {
  try {
    const config = {
      method: "HEAD",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/api/User/ValidarEstado`,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
    };

    const response = await axios.request(config);

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export default HEADValidarEstado;
