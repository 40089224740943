import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  lazy,
  Suspense,
} from "react";
import {
  Button,
  Table,
  Input,
  Tag,
  Space,
  Typography,
  Card,
  Image,
  Tooltip,
  Popconfirm,
} from "antd";
import MainLayout from "../mainLayout/MainLayout";
import {
  EditOutlined,
  SearchOutlined,
  UsergroupAddOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import GETEmpresas from "../../helpers/empresas/GETEmpresas";
import { GlobalContext } from "../context/GlobalContext";
import GETEmpresaPorCodigo from "../../helpers/empresas/GETEmpresaPorCodigo";
import { formatCUIT } from "../../utils/formatCUIT";
import "./index.css";
import GETSucursalesPorEmpresa from "../../helpers/empresas/sucursales/GETSucursalesPorEmpresa";
import { DELETEEmpresa } from "../../helpers/empresas/DELETEEmpresa";
import { useNotification } from "../notification/OpenNotification";
import { DELETESucursal } from "../../helpers/empresas/sucursales/DELETESucursal";
import GETListarIntegraciones from "../../helpers/integraciones/GETListarIntegraciones";
import SpinOnePOS from "../spinOnePOS/SpinOnePOS";

const DrawerNuevaEmpresa = lazy(() => import("./drawers/DrawerNuevaEmpresa"));
const DrawerEditarEmpresa = lazy(() => import("./drawers/DrawerEditarEmpresa"));
const DrawerUsuarios = lazy(() => import("./drawers/DrawerUsuarios"));
const DrawerEditarSucursal = lazy(() =>
  import("./drawers/DrawerEditarSucursal")
);

const Configuracion = () => {
  const openNotification = useNotification();
  const searchInput = useRef(null);
  const { loggedUser } = useContext(GlobalContext);

  const [loadingTable, setLoadingTable] = useState(false);
  const [dataEmpresas, setDataEmpresas] = useState([]);
  const [drawerNuevaEmpresa, setDrawerNuevaEmpresa] = useState(false);
  const [drawerEditarEmpresa, setDrawerEditarEmpresa] = useState({
    status: false,
    empresa: {},
  });
  const [drawerEditarSucursal, setDrawerEditarSucursal] = useState({
    status: false,
    empresa: {},
  });
  const [drawerUsuarios, setDrawerUsuarios] = useState({
    status: false,
    empresa: {},
  });

  const [adminMode, setAdminMode] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingTable(true);
      try {
        if (
          loggedUser?.codEmpresa === process.env.REACT_APP_COD_EMPRESA_ADMIN &&
          loggedUser.admin
        ) {
          setAdminMode(true);

          // Obtener las empresas
          const data = await GETEmpresas(loggedUser);

          // Ordenar empresas por nombre
          const sortedEmpresas = data.sort((a, b) =>
            a?.razonSocial.localeCompare(b?.razonSocial)
          );

          // Iterar sobre cada empresa y obtener sus integraciones
          const dataWithIntegrations = await Promise.all(
            sortedEmpresas.map(async (empresa) => {
              const integraciones = await GETListarIntegraciones(
                loggedUser,
                empresa.codigo
              );

              // Ordenar integraciones por nombre dentro de cada empresa
              const sortedIntegraciones = integraciones.sort((a, b) =>
                a.nombre.localeCompare(b.nombre)
              );

              return { ...empresa, integraciones: sortedIntegraciones };
            })
          );

          setDataEmpresas(dataWithIntegrations);
        } else {
          // No es admin, obtener la empresa y sus sucursales
          const data = await GETEmpresaPorCodigo(loggedUser);

          const sucursalesDeLaEmpresa = await GETSucursalesPorEmpresa(
            loggedUser,
            loggedUser?.codEmpresa
          );

          if (sucursalesDeLaEmpresa) {
            sucursalesDeLaEmpresa.sort((a, b) =>
              a.nombre.localeCompare(b.nombre)
            );
            setDataEmpresas([{ ...data, sucursales: sucursalesDeLaEmpresa }]);

            // Emular la acción de abrir el único row.
            await handleExpandSucursales(true, {
              codigo: loggedUser?.codEmpresa,
            });
          }
        }
      } catch (error) {
        console.error("Error al cargar los datos:", error);
      } finally {
        setLoadingTable(false); // Finaliza la carga en cualquier caso
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  const getColumnSearchProps = (dataIndex, placeholder) => {
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
    };

    const handleReset = (clearFilters, confirm, dataIndex) => {
      clearFilters();
      handleSearch([""], confirm, dataIndex);
    };

    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Buscar por ${placeholder}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() =>
                clearFilters && handleReset(clearFilters, confirm, dataIndex)
              }
              size="small"
              style={{ width: 90 }}
            >
              Reiniciar
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        return record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : false;
      },
      // onFilterDropdownOpenChange: (visible) => {
      //   if (visible) {
      //     setTimeout(() => searchInput.current?.select(), 100);
      //   }
      // },
      render: (text) => <span>{text}</span>,
    };
  };

  const handleDeleteEmpresa = async (empresa) => {
    try {
      const { status } = await DELETEEmpresa(empresa, loggedUser);

      if (status === 200) {
        const updatedEmpresas = dataEmpresas.filter(
          (item) => item.idEmpresa !== empresa.idEmpresa
        );
        setDataEmpresas(updatedEmpresas);

        openNotification({
          type: "success",
          message: "Empresa eliminada correctamente.",
          duration: 1.5,
        });
      } else {
        openNotification({
          type: "error",
          message: "No se pudo eliminar la empresa.",
          duration: 1.5,
        });
      }
    } catch (error) {
      openNotification({
        type: "error",
        message: "Hubo un error al eliminar la empresa.",
        duration: 1.5,
      });
    }
  };

  const handleDeleteSucursal = async (sucursal) => {
    const empresaIndex = dataEmpresas.findIndex(
      (empresa) => empresa.codigo === sucursal.codigoEmpresa
    );

    if (empresaIndex !== -1) {
      const updatedSucursales = dataEmpresas[empresaIndex].sucursales.filter(
        (suc) => suc.codigo !== sucursal.codigo
      );

      const updatedDataEmpresas = [...dataEmpresas];
      updatedDataEmpresas[empresaIndex] = {
        ...updatedDataEmpresas[empresaIndex],
        sucursales: updatedSucursales,
      };

      try {
        const { status } = await DELETESucursal(sucursal, loggedUser);

        if (status === 200) {
          setDataEmpresas(updatedDataEmpresas);

          openNotification({
            type: "success",
            message: "Sucursal eliminada correctamente.",
            duration: 1.5,
          });
        }
      } catch (error) {
        console.error("Error al eliminar la sucursal:", error);
      }
    } else {
      console.error("Empresa no encontrada");
    }
  };

  const RenderIntegrations = React.memo(({ integrations }) => {
    const activeIntegrations = integrations.filter((x) => x.activo);

    if (!activeIntegrations || activeIntegrations.length === 0) {
      return (
        <div style={{ textAlign: "flex-start", padding: "8px" }}>
          <Typography.Text type="secondary">
            No hay integraciones activas.
          </Typography.Text>
        </div>
      );
    }

    return (
      <div className="card-integration-container">
        {activeIntegrations.map((item, index) => {
          const imageSrc = `${process.env.REACT_APP_IMAGES_URL}${item.imagen}`;

          return (
            <Tooltip title={item.nombre} key={item.codigo}>
              <Card
                style={{
                  boxShadow: "none",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="integration-wrapper"
                key={item.idIntegracion}
                hoverable
                bordered={false}
              >
                <Image src={imageSrc} preview={false} width={30} />
              </Card>
            </Tooltip>
          );
        })}
      </div>
    );
  });

  const columns = [
    {
      title: "Código",
      dataIndex: "codigo",
      key: "codigo",
      ellipsis: true,
      ...getColumnSearchProps("codigo", "código"),
      render: (dataIndex) => {
        return <span style={{ fontFamily: "monospace" }}>{dataIndex}</span>;
      },
      width: "8%",
    },
    {
      title: "Razón social",
      dataIndex: "razonSocial",
      key: "razonSocial",
      width: adminMode ? "35%" : "50%",
      ellipsis: true,
      ...getColumnSearchProps("razonSocial", "razón social"),
      render: (dataIndex) => {
        return <Typography.Text strong>{dataIndex}</Typography.Text>;
      },
    },
    {
      title: "CUIT",
      dataIndex: "cuit",
      key: "cuit",
      ellipsis: true,
      ...getColumnSearchProps("cuit", "CUIT"),
      sorter: (a, b) => {
        return a.cuit.localeCompare(b.cuit);
      },
      align: "right",
      width: adminMode ? "10%" : "15%",
      render: (dataIndex) => {
        return (
          <span style={{ fontFamily: "monospace" }}>
            {formatCUIT(dataIndex)}
          </span>
        );
      },
    },
    ...(adminMode
      ? [
          {
            title: "Integraciones",
            dataIndex: "",
            key: "integraciones",
            ellipsis: true,
            width: "40%",
            render: (dataIndex, row) => (
              <RenderIntegrations integrations={row?.integraciones || []} />
            ),
          },
        ]
      : []),
    {
      title: " ",
      dataIndex: "",
      key: "action2",
      ellipsis: true,
      render: (text, record) => (
        <div
          style={{
            justifyContent: "space-around",
            display: "flex",
            width: "100px",
          }}
        >
          <Tooltip title="Gestionar usuarios">
            <UsergroupAddOutlined
              className="edit-icon"
              onClick={() =>
                setDrawerUsuarios({ status: true, empresa: { ...record } })
              }
            />
          </Tooltip>

          <Tooltip title="Editar empresa">
            <EditOutlined
              className="edit-icon"
              onClick={async () => {
                const sucursalesEmpresa = await handleExpandSucursales(
                  true,
                  record
                );

                setDrawerEditarEmpresa({
                  status: true,
                  empresa: { ...record, sucursales: sucursalesEmpresa },
                });
              }}
            />
          </Tooltip>
          {adminMode && (
            <Tooltip title="Eliminar empresa">
              <Popconfirm
                title="¿Estás seguro de eliminar esta empresa?"
                onConfirm={() => handleDeleteEmpresa(record)}
                okText="Sí"
                cancelText="No"
              >
                <DeleteOutlined className="edit-icon" />
              </Popconfirm>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  const subColumns = [
    {
      title: "Código",
      dataIndex: "codigo",
      key: "codigo",
      render: (dataIndex, row) => {
        return <span style={{ fontFamily: "monospace" }}>{dataIndex}</span>;
      },
      width: "10%",
      sorter: (a, b) => {
        return a.codigo.localeCompare(b.codigo);
      },
    },
    {
      title: "Sucursal",
      dataIndex: "nombre",
      key: "nombre",
      width: "30%",
    },
    {
      title: "Cajas",
      key: "cajas",
      width: "25%",
      render: (text, record) => (
        <>
          {record?.cajas && record.cajas.length > 0 ? (
            record.cajas.map((caja) => {
              return (
                <Tag
                  className="custom-tag"
                  bordered={false}
                  key={caja.codigoERP + caja.codSucursal}
                >
                  {caja.nombre}
                </Tag>
              );
            })
          ) : (
            <span style={{ color: "grey" }}>No hay cajas asociadas.</span>
          )}
        </>
      ),
    },
    {
      title: " ",
      dataIndex: "",
      key: "action2",
      width: "5%",
      render: (text, record) => (
        <div style={{ justifyContent: "space-around", display: "flex" }}>
          <Tooltip title="Editar sucursal">
            <EditOutlined
              className="edit-icon"
              onClick={() =>
                setDrawerEditarSucursal({
                  status: true,
                  sucursal: record,
                })
              }
            />
          </Tooltip>
          <Tooltip title="Eliminar sucursal">
            <DeleteOutlined
              className="edit-icon"
              onClick={() => handleDeleteSucursal(record)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleDrawerNuevaClose = async (newEmpresa) => {
    // Callback del drawer para actualizar state una vez se crea una empresa
    const integracionesEmpresaNueva = await GETListarIntegraciones(
      loggedUser,
      newEmpresa.codigo
    );

    const nuevaEmpresaPayload = {
      ...newEmpresa,
      integraciones: integracionesEmpresaNueva,
    };

    setDataEmpresas((prevData) => {
      // Inserta la nueva empresa y ordena el array alfabéticamente por el nombre
      const updatedData = [...prevData, nuevaEmpresaPayload].sort(
        (a, b) => a.razonSocial.localeCompare(b.razonSocial) // Compara el nombre de las empresas alfabéticamente
      );
      return updatedData;
    });

    setDrawerNuevaEmpresa({ status: false });
  };

  const handleDrawerEditarClose = (updatedEmpresa) => {
    setDataEmpresas((prevData) => {
      return prevData.map((empresa) => {
        // Verifica si la empresa coincide por codigo
        if (empresa.codigo === updatedEmpresa.codigo) {
          // Retorna la empresa actualizada con los nuevos datos y las sucursales actualizadas
          return {
            ...empresa,
            cuit: updatedEmpresa.cuit, // Actualiza el cuit
            razonSocial: updatedEmpresa.razonSocial, // Actualiza la razonSocial
            sucursales: empresa?.sucursales?.map((sucursal) => {
              // Verifica si la sucursal coincide por id
              if (sucursal.id === updatedEmpresa.sucursales.id) {
                // Retorna la sucursal actualizada
                return {
                  ...sucursal,
                  ...updatedEmpresa.sucursales, // Asume que los datos que llegan son completos
                };
              }
              return sucursal; // Retorna la sucursal sin cambios
            }),
          };
        }
        return empresa; // Retorna la empresa sin cambios
      });
    });
  };

  const handleDrawerSucursalClose = (updatedSucursal) => {
    setDataEmpresas((prevEmpresas) => {
      return prevEmpresas.map((empresa) => {
        // Si es la empresa correcta
        if (empresa.codigo === updatedSucursal.codEmpresa) {
          return {
            ...empresa,
            sucursales: empresa.sucursales.map((sucursal) =>
              sucursal.codigo === updatedSucursal.codigo
                ? {
                    ...sucursal,
                    ...updatedSucursal, // Actualizamos la sucursal con los datos recibidos
                    cajas: updatedSucursal.cajas || sucursal.cajas, // Aseguramos que "cajas" se actualice correctamente
                  }
                : sucursal
            ),
          };
        }
        return empresa; // Devolver otras empresas sin cambios
      });
    });
  };

  const handleExpandSucursales = async (expanded, record) => {
    if (expanded) {
      setLoadingTable(true);
      try {
        // Obtener las sucursales de la empresa
        const sucursales = await GETSucursalesPorEmpresa(
          loggedUser,
          record.codigo
        );
        sucursales.sort((a, b) => a.nombre.localeCompare(b.nombre));
        if (sucursales && sucursales.length > 0) {
          setDataEmpresas((prevData) =>
            prevData.map((empresa) =>
              empresa.codigo === record.codigo
                ? { ...empresa, sucursales }
                : empresa
            )
          );
          return sucursales;
        }
      } catch (error) {
        console.error("Error fetching sucursales o cajas:", error);
      } finally {
        setLoadingTable(false);
      }
    }
  };

  return (
    <MainLayout>
      <div className="filter-wrapper">
        {adminMode && (
          <Button
            type="primary"
            onClick={() => setDrawerNuevaEmpresa({ status: true })}
          >
            Nueva empresa
          </Button>
        )}
      </div>

      <Table
        loading={{
          spinning: loadingTable,
          indicator: (
            <div style={{ marginTop: 50 }}>
              <SpinOnePOS />
            </div>
          ),
        }}
        sticky={{ offsetHeader: 100 }}
        scroll={{ x: "max-content" }}
        bordered
        size="small"
        columns={columns}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 50,
        }}
        dataSource={dataEmpresas}
        rowKey="idEmpresa"
        expandable={{
          expandedRowRender: (record) => (
            <Table
              bordered
              size="small"
              style={{ padding: 0 }}
              columns={subColumns}
              dataSource={record?.sucursales?.map((sucursal) => ({
                ...sucursal,
                codigoEmpresa: record.codigo, // Mapeo datos de la empresa para cada sucursal, así tengo referencia
                razonSocial: record.razonSocial,
              }))}
              pagination={false}
              rowKey="codigo"
              locale={{
                emptyText: "No hay sucursales para esta empresa.",
              }}
            />
          ),
          // Seteo default expanded cuando solo hay una empresa, aplicable para vista única
          expandedRowKeys: dataEmpresas &&
            dataEmpresas.length === 1 && [dataEmpresas[0]?.idEmpresa],
          onExpand: handleExpandSucursales,
        }}
      />

      {drawerNuevaEmpresa && drawerNuevaEmpresa.status && (
        <Suspense fallback={<SpinOnePOS />}>
          <DrawerNuevaEmpresa
            drawerVisible={drawerNuevaEmpresa}
            setDrawerVisible={setDrawerNuevaEmpresa}
            onClose={handleDrawerNuevaClose}
          />
        </Suspense>
      )}

      {drawerEditarEmpresa && drawerEditarEmpresa.status && (
        <Suspense fallback={<SpinOnePOS />}>
          <DrawerEditarEmpresa
            drawerPayload={drawerEditarEmpresa}
            setDrawerPayload={setDrawerEditarEmpresa}
            onClose={handleDrawerEditarClose}
          />
        </Suspense>
      )}

      {drawerEditarSucursal && drawerEditarSucursal.status && (
        <Suspense fallback={<SpinOnePOS />}>
          <DrawerEditarSucursal
            drawerPayload={drawerEditarSucursal}
            setDrawerPayload={setDrawerEditarSucursal}
            onClose={handleDrawerSucursalClose}
          />
        </Suspense>
      )}

      {drawerUsuarios && drawerUsuarios.status && (
        <Suspense fallback={<SpinOnePOS />}>
          <DrawerUsuarios
            drawerPayload={drawerUsuarios}
            setDrawerPayload={setDrawerUsuarios}
          />
        </Suspense>
      )}
    </MainLayout>
  );
};

export default Configuracion;
