import React, { useEffect, useState } from "react";
import { HashRouter as Router } from "react-router-dom";
import { GlobalContext } from "./components/context/GlobalContext";
import { ConfigProvider } from "antd";
import esES from "antd/locale/es_ES";
import "./App.css";
import {
  getDataInStorage,
  removeDataInStorage,
} from "./utils/manageLocalStorage";
import GETListarIntegraciones from "./helpers/integraciones/GETListarIntegraciones";
import verifyAccessToken from "./utils/verifyAccessToken";
import NotificationProvider from "./components/notification/OpenNotification";
import AppRoutes from "./components/routes/AppRoutes";
import SpinOnePOS from "./components/spinOnePOS/SpinOnePOS";
import APIHealth from "./components/HOC/APIHealth";

const App = () => {
  const [loggedUser, setLoggedUser] = useState({});
  const [allIntegrations, setAllIntegrations] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    const currentFavicon = document.getElementById("favicon");
    if (!mediaQuery.matches) {
      currentFavicon.href = "favicon.ico";
    } else {
      currentFavicon.href = "favicon_blanco.ico";
    }
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      const res = await getDataInStorage("userInfo");
      if (res) {
        const tokenStatus = verifyAccessToken(res); // analiza las credenciales actuales y retorna un boolean
        if (!tokenStatus) {
          setLoggedUser(res);

          const fetchData = async () => {
            // Sale a buscar las integraciones en el caso de tener sesión abierta
            const result = await GETListarIntegraciones(res, res?.codEmpresa);
            // Ordeno alfabeticamente las integraciones
            if (Array.isArray(result)) {
              result?.sort((a, b) => a.nombre.localeCompare(b.nombre));
              setAllIntegrations(result);
            }
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          };
          fetchData();
        } else {
          setLoggedUser({});
          removeDataInStorage("userInfo");
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      } else {
        setLoggedUser({});
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    };

    fetchUserData();
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <SpinOnePOS />
      </div>
    );
  }

  return (
    <GlobalContext.Provider
      value={{
        loggedUser,
        setLoggedUser,
        selectedIntegration,
        setSelectedIntegration,
        setAllIntegrations,
      }}
    >
      <ConfigProvider
        locale={esES}
        theme={{
          token: {
            colorPrimary: "#1e3661",
          },
        }}
      >
        <NotificationProvider>
          <Router>
            <AppRoutes
              loggedUser={loggedUser}
              allIntegrations={allIntegrations}
            />
          </Router>
        </NotificationProvider>
      </ConfigProvider>
    </GlobalContext.Provider>
  );
};

export default APIHealth(App);
