import React, { useContext, useState } from "react";
import { Button, Divider, Dropdown, Space, Image, Input, Form } from "antd";
import {
  UserOutlined,
  LeftOutlined,
  LockOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { GlobalContext } from "../../context/GlobalContext";
import { removeDataInStorage } from "../../../utils/manageLocalStorage";
import "../index.css";
import POSTCambiarClave from "../../../helpers/usuarios/POSTCambiarClave";
import { useNotification } from "../../notification/OpenNotification";

const ProfileSettings = ({ children }) => {
  const openNotification = useNotification();
  const [loadings, setLoadings] = useState([]);
  const { loggedUser, setLoggedUser } = useContext(GlobalContext);
  const [isChangePasswordVisible, setChangePasswordVisible] = useState(false);

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 1500);
  };

  const handleLogout = () => {
    enterLoading(0);
    setTimeout(() => {
      removeDataInStorage("userInfo");
      setLoggedUser({});
    }, 1500);
  };

  const handlePasswordChange = async (values) => {
    const passwordPayload = {
      email: loggedUser.email,
      oldPassword: values.currentPassword,
      newPassword: values.newPassword,
    };

    const response = await POSTCambiarClave(loggedUser, passwordPayload);

    if (response.status === 200) {
      // Mostrar notificación de éxito primero
      openNotification({
        type: "success",
        message: "Contraseña actualizada con éxito. Cerrando sesión...",
        duration: 2, // Duración de la notificación
      });

      // Esperar a que la notificación termine antes de ejecutar el logout
      setTimeout(() => {
        setChangePasswordVisible(false); // Ocultar modal o sección de cambio de contraseña
        handleLogout(); // Cerrar sesión y limpiar la sesión en localStorage/context
      }, 2000); // Coincide con la duración de la notificación
    } else {
      // Mostrar notificación de error
      openNotification({
        type: "error",
        message: "No fue posible actualizar la contraseña.",
        duration: 1.5,
      });
    }
  };

  const ChangePasswordMenu = () => (
    <div className="profile-dropdown">
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}
      >
        <Button
          onClick={() => setChangePasswordVisible(false)}
          style={{
            marginRight: "8px",
            border: "none",
            background: "none",
            padding: 0,
            fontSize: "16px",
          }}
          icon={<LeftOutlined className={"edit-icon"} />}
        />
        <h3 style={{ margin: 0 }}>Cambiar contraseña</h3>
      </div>
      <Form layout="vertical" onFinish={handlePasswordChange}>
        <Form.Item
          label="Contraseña actual"
          name="currentPassword"
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Nueva contraseña"
          name="newPassword"
          dependencies={["currentPassword"]}
          rules={[
            {
              required: true,
              message: "",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || value !== getFieldValue("currentPassword")) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    "La nueva contraseña no puede ser igual a la actual"
                  )
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Confirmar nueva contraseña"
          name="confirmPassword"
          dependencies={["newPassword"]}
          rules={[
            {
              required: true,
              message: "",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Las contraseñas no coinciden")
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: "100%", marginBottom: "10px" }}
        >
          Confirmar
        </Button>
        <Button
          type="dashed"
          style={{ width: "100%" }}
          onClick={() => setChangePasswordVisible(false)}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  );

  const DefaultMenu = () => (
    <div className="profile-dropdown">
      <div className="profile-header-dropdown">
        <Image
          src={`${process.env.REACT_APP_IMAGES_URL}${loggedUser?.imagen}`}
          width={120}
          preview={false}
        />
      </div>
      <span>
        <UserOutlined /> {loggedUser?.nombre} {loggedUser?.apellido}
      </span>

      <Button
        type="default"
        onClick={() => setChangePasswordVisible(true)}
        style={{
          marginTop: "12px",
          width: "100%",
        }}
      >
        Cambiar contraseña <LockOutlined />
      </Button>
      <Divider
        style={{
          margin: "8px 0",
        }}
      />

      <Space
        style={{
          padding: "8px 0",
          display: "block",
        }}
      >
        <Button
          type="primary"
          onClick={() => handleLogout()}
          loading={loadings[0]}
          style={{
            width: "100%",
          }}
        >
          Cerrar sesión <LogoutOutlined />
        </Button>
      </Space>
    </div>
  );

  return (
    <Dropdown
      // open={isChangePasswordVisible ? true : null} // Posible manejo de estado
      placement="bottomRight"
      arrow={{
        pointAtCenter: false,
      }}
      trigger={"click"}
      overlayStyle={{ pointerEvents: "auto" }}
      dropdownRender={() =>
        isChangePasswordVisible ? <ChangePasswordMenu /> : <DefaultMenu />
      }
    >
      {children}
    </Dropdown>
  );
};

export default ProfileSettings;
